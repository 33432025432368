.container-appbar {
  width: 100vw;
}
.container-appbar .MuiAppBar-root {
  background-color: white;
  box-shadow: none;
}

.menu-atag {
  color: black;
}

.container-menu .MuiContainer-root {
  background-color: white;
  border: none;
  box-shadow: none;

}

.container-menuicon .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}

/* .cnr-navroute .MuiButton-root {
  text-decoration: none !important;
}

.cnr-navroute .MuiTypography-root {
  text-decoration: none !important;
} */

.cnr-navroute .MuiTouchRipple-root {
  display: none !important;
}

.atag {
  text-decoration: none !important;
}

.container-search {
  margin-left: 10vw;
  font-size: 20px !important;
}

.container-search .MuiSvgIcon-root {
  height: 100px;
  color: black;
  display: inline-block;
  font-size: 25px !important;
}

.container-search .MuiInputBase-root {
  height: 100px;
  color: black;
  display: inline-block;
  padding: 0px !important;
  font-size: 15px !important;
}

.container-search .MuiInputBase-input {
  height: 100px;
  color: black;
  padding-top: 0px !important;
}

.container-landing .MuiMobileStepper-dot {
  background-color: pink;
  width: 14px;
  height: 14px;
}

.container-landing .MuiMobileStepper-dotActive {
  background-color: #891832;
  width: 14x;
  height: 14px;
}

.container-fav .MuiSvgIcon-root {
  width: 60px;
  height: 60px;
}

.container-pick .MuiButton-root {
  background-color: #891832;
  height: 28px;
  font-size: 13px !important;
}

.container-browse  .MuiButton-root {
  margin-top: 60px;
  color: #891832;
  width: 300px;
}

.container-browse  .MuiButton-text {
  font-size: 17px !important;
  font-style: italic !important;
  text-transform: none !important;
  text-decoration: underline;
}


.container-about .MuiButton-root {
  background-color: #891832;
  font-size: 13px;
  width: 190px !important;
}

.container-card .MuiCard-root {
  border-radius: 40px !important;
}

.container-rating .MuiRating-root {
  color: #891832;
}

.container-ftricon .MuiSvgIcon-root {
  color: white;
  width: 50px;
  height: 50px;
}

.footer-atag {
  color: white;
}

.container-divider .MuiDivider-root {
  margin-top: 20px;
  width: 600px;
  background-color: white;
}

.container-scrollup .MuiSvgIcon-root {
  color: white;
  width: 100px;
  height: 100px;
}

* .MuiButton-root {
  width: 110px;
  height: 40px;
  border-radius: 40px !important;
}

* .MuiButton-root:hover {
  background-color: pink !important;
  box-shadow: 1px 1px #ffa4af;
}

* .MuiButton-root {
  font-size: 16px !important;
}

.container-buy .MuiButton-root {
  background-color: #891832;
  font-size: 12px !important;
}

.container-buymodal .buy-atag  .MuiSvgIcon-root {
  color: black;
  width: 70px;
  height: 70px;
}

.closeicon .MuiSvgIcon-root {
  color: black;
  width: 40px;
  height: 40px;
}


.closeicon .MuiSvgIcon-root:hover {
  cursor: pointer;
}


@media only screen and (max-width: 1000px) {
  * .MuiButton-root {
    width: 220px;
    height: 40px;
    border-radius: 20px !important;
  }
  
  * .MuiButton-root:hover {
    background-color: pink !important;
    box-shadow: 3px 3px #ffa4af;
  }
  
  * .MuiButton-root {
    font-size: 18px !important;
  }

  .container-browse  .MuiButton-root {
    color: #891832;
    margin-top: 10px;
  }
  
  .container-browse  .MuiButton-text {
    font-size: 17px !important;
    font-style: italic !important;
    text-transform: none !important;
    text-decoration: underline;
  }
}